/* custom component css */
.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 28px;
    height: 56px;
    background: #D30263;
}
.button-container:hover {
    background: #fff;
    box-shadow: 0 3pt 6pt #00000029;
}
.button-container:hover .button {
    color: #000;
    font-weight: 600;
}
.button-confirm {
    width: 212px;
    height: 56px;
}
.button-confirm .button p {
    font-size: 20px;
}
.button-default {
    width: 212px;
    height: 56px;
}
.button-default .button p {
    font-size: 16px;
}
.button-login {
    width: 85%;
    margin: 40px 0;
}
.button-add {
    background: #fff;
    width: 330px;
}
.button-add .icon-add-circle {
    fill: #000;
}
.button-add .button {
    color: #000;
}

.button-add:hover {
    background: #D30263;
}
.button-add:hover .button {
    color: #fff;
    font-weight: 600;
}
.button-add:hover .icon-add-circle {
    fill: #fff;
}

.button-delete {
    background: transparent;
    width: fit-content;

}
.button-delete .button p {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
    padding-right: 12px;
}
.button-delete:hover {
    background: transparent;
    box-shadow: none;
}
.button-delete:hover .button p {
    font-weight: bold;
}

.button-selection-filter {
    display: flex;
    flex-direction: row;
}

.button-selection {
    background: #fff;
}
.button-selection .button p {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
}
.button-selection:hover, .button-selected {
    background: #D30263;
    box-shadow: 0px 3px 6px #00000029;
}
.button-selection:hover .button p, .button-selected .button p {
    color: #fff;
}

.button-container.button-default.button-selection.button-disabled {
    opacity: 0.5;
    background: white !important;
}

.button-confirm {
    width: 212px;
    height: 56px;
    margin: 0 20px;
}
.button-reject {
    width: 140px;
}
.button-reject {
    background: transparent;
}
.button-reject .button {
    color: #707070;
}
.button-reject:hover {
    box-shadow: none;
}

.button-upload {
    width: 580px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #fff;
}
.button-upload .button p {
    font-size: 50px;
    color: #0A0A0A;
    font-weight: normal;
    padding: 60px 80px;
    padding-bottom: 0;
}
.button-upload:hover .button p {
    color: #0A0A0A;
    font-weight: normal;
}

.button-combo-offer {
    height: 148px;
    width: 348px;
    flex-direction: row;
    border: 1px #707070 dashed;
}
.button-combo-offer .button p {
    font-size: 16px;
    font-weight: normal;
    color: #707070;
    padding: 0;
    padding-left: 11px;
}
.button {
    text-align: center;
    color: #fff;
    font-size: 16px;
}
.button > p {
    font-weight: bold;
}
.button-container .icon {
    margin-right: 8px;
}
.button-disabled.button-container.button-default {
    opacity: 0.6;
}

.button-disabled.button-container.button-default:hover {
    background-color: #D30263;
    cursor: unset;
    box-shadow: unset;
}

.button-disabled:hover .button {
    color: #fff;
}

.l10n-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 48px;
}

.l10n-button-group > div.button-selection {
    margin: 0 20px;
}
