.happy-hour-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
}
.happy-hour-container .checkbox-container {
    margin-top: 12px;
}

.react-timerange-picker__wrapper {
    border-radius: 4px;
    height: 50px;
}

/* PRODUCT PAGE */
.time-picker {
    margin-top: 8px;
    margin-right: 70px;
}
.time-picker .react-timerange-picker__wrapper {
    background-color: #ffffff;
}