.message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 56px;
}

.message.error p {
    font-size: 14px;
    font-weight: bold;
    color: red;
}