.page.menu-created .body svg {
    margin-bottom: 50px;
}
.page.menu-created .body p {
    text-align: center;
}
.menu-created-message {
    font-size: 30px;
    font-weight: bold;
    color: #0A0A0A;
    margin: 23px;
}
.menu-created-note {
    font-size: 14px;
    color: #707070;
    margin: 6px;
}