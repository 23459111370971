.container-img-upload {
    position: relative;
    left: 370px;
    top: -148px;
    width: 150px;
    object-fit: contain;
}
.container-img-upload .icon-close {
    font-size: 24px;
    fill: #707070;
    position: relative;
    right: 10px;
    top: -100px;
    cursor: pointer;
}