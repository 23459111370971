form input {
    height: 56px;
    background: #F0F0F0;
    border: none;
    outline: none;
    padding-left: 12px;
    font-size: 16px;
}
form label ::placeholder {
    font-weight: 400;
    color:#707070;
}
form textarea:focus {
    outline: none;
}
form input:disabled {
    opacity: 0.5;
}
.form-container .p-info {
    text-align: center;
    font-size: 9pt;
    color: #707070;
    font-style: italic;
}
.form-container {
    margin-bottom: 50px;
}

.input-default {
    border-radius: 5px;
    font-size: 16px;
    color: #707070;
    width: 100%;
}
.input-shadow {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5pt solid #707070;
}
.input-white {
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 16px;
    color: #707070;
}
.input-textarea {
    border: none;
    height: 295px;
    min-height: 295px;
    max-height: 800px;
    max-width: 100%;
    min-width: 100%;
    padding: 24px 20px;
}
.input-price {
    width: 228px;
    height: 48px;
    margin-right: 16px;
    margin-top: 9px;
    border-radius: 5px;
    font-size: 14px;
}
form input.input-login {
    margin-bottom: 36px;
}
form input.input-default {
    margin-bottom: 16px;
}
.input-sharepoint_folder_description {
    height: 40px;
    margin: 0 !important;
}