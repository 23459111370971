@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: local('Poppins'), url(/static/media/Poppins-Regular.8081832f.ttf) format('truetype');
}
@font-face {
    font-family: 'Poppins-Light';
    src: local('Poppins-Light'), url(/static/media/Poppins-Light.f41b63c0.ttf) format('truetype');
}
@font-face {
    font-family: 'Poppins-Thin';
    src: local('Poppins-Thin'), url(/static/media/Poppins-Thin.a61eaf4c.ttf) format('truetype');
}
@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'), url(/static/media/Poppins-Bold.404e299b.ttf) format('truetype');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
}
p, a, div, img {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
p {
    font-size: 11pt;
    font-family: 'Raleway', sans-serif;
}
a {
    cursor: pointer;
}
.p-title-small {
    font-weight: bold;
    padding-bottom: 15px;
    font-size: 16px;
    color: #0A0A0A;
}
.p-input-label {
    font-size: 16px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.p-label-over {
    position: absolute;
    z-index: 99;
    font-size: 16px;
    background: white;
    left: 17px;
}

.p-title-category {
    font-weight: bold;
    padding-bottom: 13px;
    font-size: 14px;
    color: #D30263;
}
.p-title-product {
    font-weight: bold;
    padding-bottom: 9px;
    font-size: 14px;
    color: #0A0A0A;
}
.p-desc-product {
    font-weight: normal;
    padding-bottom: 9px;
    font-size: 12px;
    color: #0A0A0A;
}
a, p {
    line-height: 1.15;
}
a.a-icon {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.main {
    display: flex;
    height: 100%;
    width: 100vw;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #F0F0F0;
}
.main.before:before {
    background: url(/static/media/background-cp.56b9c961.jpg);
    background-size: cover;
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 1;
    opacity: 0.58;
}

.page {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.page.relative .body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 140px;
    /*padding-left: 440px;*/
}
.page.centered .body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    /*padding-left: 390px;*/
}
.page.digital-menu .body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 27px;
    padding-bottom: 0 !important;
    margin-top: 0px;
}
.container-column-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}

.container-column-2 .column-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 50%;
}

.container-row-2 .row-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.container-row-2 .row-2 .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 50%;
    justify-content: flex-start;
}
.container-row-2 .row-2 .row:first-child {
    padding-right: 32px;
}
.container-row-2 .row-2 form {
    width: 100%;
}
.container-row-2 .row-2 .row form {
    padding-left: 32px !important;
}
.container-row-2 .row-2 form:first-child, .container-column-2 .column-2:first-child {
    padding-right: 16px;
}
.container-row-2 .row-2 form:last-child, .container-column-2 .column-2:last-child, .container-row-2 .row-2 form:nth-child(3) {
    padding-left: 16px;
}

.icon.arrow {
    cursor: pointer;
}

.rotate {
    -webkit-transform: rotate(
            180deg
    );
            transform: rotate(
            180deg
    );
}

.body.beer-cider-page .l10n-button-group .button-selection:not(.button-selected) {
    display: none;
}

/* STATISTICS PAGE */

.stats-card {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 170px;
    background: white;
    align-items: center;
    border-radius: 5px;
}
.p-stats {
    font-size: 45px;
    color: #0A0A0A;
}
.icon-stats {
    height: 60px;
    width: 50px;
    object-fit: contain;
}
.stats-card form {
    width: 45%;
}
.stats-card form .select-default {
    margin: 0;
}
.stats-card form .select-default .select-default__value-container {
    /*padding: 0*/
}
.stats-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
    height: 70px;
    border-bottom: 1px #707070 solid;
    position: absolute;
    top: 90px;
    right: 50px;
}
.stats-selection {
    padding: 0px 30px;
    cursor: pointer;
    size: 18px;
    color: #6A6A6A;
    border-bottom: none;
    font-weight: bold;
}
.stats-selection.selected {
    width: auto;
    text-decoration: underline;
    color: #E3021C;
}
.stats-divider {
    height: 1px;
    border-bottom: 1px #707070 solid;
    width: 100%;
}
ul.stats-item-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
}
.column-2:first-child ul.stats-item-list {
    align-items: flex-start;
    padding-bottom: 50px !important;

}
.column-2:last-child ul.stats-item-list {
    align-items: flex-end;
    padding-right: 50px;
    padding-bottom: 50px !important;

}
.stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px;
    background: #F0F0F0;
    min-height: 50px;
    border-radius: 20px;
    margin: 4px 0;
}
.stats-inner-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
}
.stats-inner-row > div {
    min-width: 100px;
    font-size: 13px;
    padding: 0 4px;
}
.stats-row > .row-name {
    font-size: 15px;
    min-width: 160px;
    max-width: 160px;
}
.stats-inner-row > div:first-child {
    text-align: right;
}
.stats-inner-row > div:last-child {
    text-align: left;
}
.row-value {
    font-size: 28px;
}
.row-remove {
    padding: 0 10px;
    font-weight: bolder;
    cursor: pointer;
}
.row-remove:hover, p.stats-reset:hover {
    color: #E3021C;
    cursor: pointer;
}
p.stats-reset {
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0.12px;
    color: #0A0A0A;
    opacity: 1;
    font-size: 12px;
    min-width: 70px;
}
.stats-body-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 70px;

}

.message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 56px;
}

.message.error p {
    font-size: 14px;
    font-weight: bold;
    color: red;
}
/* custom component css */
.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 28px;
    height: 56px;
    background: #D30263;
}
.button-container:hover {
    background: #fff;
    box-shadow: 0 3pt 6pt #00000029;
}
.button-container:hover .button {
    color: #000;
    font-weight: 600;
}
.button-confirm {
    width: 212px;
    height: 56px;
}
.button-confirm .button p {
    font-size: 20px;
}
.button-default {
    width: 212px;
    height: 56px;
}
.button-default .button p {
    font-size: 16px;
}
.button-login {
    width: 85%;
    margin: 40px 0;
}
.button-add {
    background: #fff;
    width: 330px;
}
.button-add .icon-add-circle {
    fill: #000;
}
.button-add .button {
    color: #000;
}

.button-add:hover {
    background: #D30263;
}
.button-add:hover .button {
    color: #fff;
    font-weight: 600;
}
.button-add:hover .icon-add-circle {
    fill: #fff;
}

.button-delete {
    background: transparent;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}
.button-delete .button p {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
    padding-right: 12px;
}
.button-delete:hover {
    background: transparent;
    box-shadow: none;
}
.button-delete:hover .button p {
    font-weight: bold;
}

.button-selection-filter {
    display: flex;
    flex-direction: row;
}

.button-selection {
    background: #fff;
}
.button-selection .button p {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
}
.button-selection:hover, .button-selected {
    background: #D30263;
    box-shadow: 0px 3px 6px #00000029;
}
.button-selection:hover .button p, .button-selected .button p {
    color: #fff;
}

.button-container.button-default.button-selection.button-disabled {
    opacity: 0.5;
    background: white !important;
}

.button-confirm {
    width: 212px;
    height: 56px;
    margin: 0 20px;
}
.button-reject {
    width: 140px;
}
.button-reject {
    background: transparent;
}
.button-reject .button {
    color: #707070;
}
.button-reject:hover {
    box-shadow: none;
}

.button-upload {
    width: 580px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #fff;
}
.button-upload .button p {
    font-size: 50px;
    color: #0A0A0A;
    font-weight: normal;
    padding: 60px 80px;
    padding-bottom: 0;
}
.button-upload:hover .button p {
    color: #0A0A0A;
    font-weight: normal;
}

.button-combo-offer {
    height: 148px;
    width: 348px;
    flex-direction: row;
    border: 1px #707070 dashed;
}
.button-combo-offer .button p {
    font-size: 16px;
    font-weight: normal;
    color: #707070;
    padding: 0;
    padding-left: 11px;
}
.button {
    text-align: center;
    color: #fff;
    font-size: 16px;
}
.button > p {
    font-weight: bold;
}
.button-container .icon {
    margin-right: 8px;
}
.button-disabled.button-container.button-default {
    opacity: 0.6;
}

.button-disabled.button-container.button-default:hover {
    background-color: #D30263;
    cursor: unset;
    box-shadow: unset;
}

.button-disabled:hover .button {
    color: #fff;
}

.l10n-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 48px;
}

.l10n-button-group > div.button-selection {
    margin: 0 20px;
}

.container-button-group-selection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.button-group-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.sidebar {
    width: 390px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    z-index: 2;
    position: fixed;
}

.sidebar a {
    font-size: 15px;
    font-weight: 600;
    color: #707070;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.sidebar .logo {
    padding-top: 50px;
    padding-bottom: 20px;
    cursor: pointer;
}

.sidebar ul {
    margin: 0;;
    padding: 0;
    outline: none;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 68%;
    zoom: 0.9;
}

.sidebar ul li {
    margin: 11px 0;
    cursor: pointer;
}

.sidebar ul li.collapsed {
    margin: 12px 0;
}

.sidebar ul li .icon {
    margin: 0 15px;
}

.sidebar ul li .icon .icon-edit-primary {
    stroke: #707070;
}

.btn-logout {
    text-decoration: underline;
    color: #707070;
    align-self: center;
    position: absolute;
    bottom: 35px;
}

.btn-logout p {
    font-size: 8pt;
    font-weight: 200;
    cursor: pointer;
}

.sidebar ul li .submenu-item {
    font-size: 14px;
    cursor: pointer;
    opacity: 1;
    padding-bottom: 8px;
    padding-left: 55px;
}
.sidebar ul li .submenu-item.before {
    color: #707070;
}
.sidebar ul li .submenu-item.current {
    color: #707070;
}
.sidebar ul li .submenu-item.after {
    opacity: 0.39;
}

.sidebar.hide {
    display: none;
}
.divider {
    width: 68%;
    outline: none;
    border: none;
    background: #a0a0a0;
    height: 1px;
}
.login-container {
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 120px;
    background: #fff;
    border-radius: 25px;
}
.logo {
    padding-top: 30px;
    padding-bottom: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.form-container * {
    width: 100%;
}
.login-container .message {
    margin-bottom: -20px;
}
.checkbox-container {
    margin-top: 22px;
}
.checkbox-container input {
    margin: 0 12px;
    height: 15px;
    width: 15px;
}
.checkbox-container input:checked {
    background: #D30263;
}
.checkbox-container label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
}

form input {
    height: 56px;
    background: #F0F0F0;
    border: none;
    outline: none;
    padding-left: 12px;
    font-size: 16px;
}
form label ::-webkit-input-placeholder {
    font-weight: 400;
    color:#707070;
}
form label :-ms-input-placeholder {
    font-weight: 400;
    color:#707070;
}
form label ::placeholder {
    font-weight: 400;
    color:#707070;
}
form textarea:focus {
    outline: none;
}
form input:disabled {
    opacity: 0.5;
}
.form-container .p-info {
    text-align: center;
    font-size: 9pt;
    color: #707070;
    font-style: italic;
}
.form-container {
    margin-bottom: 50px;
}

.input-default {
    border-radius: 5px;
    font-size: 16px;
    color: #707070;
    width: 100%;
}
.input-shadow {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5pt solid #707070;
}
.input-white {
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 16px;
    color: #707070;
}
.input-textarea {
    border: none;
    height: 295px;
    min-height: 295px;
    max-height: 800px;
    max-width: 100%;
    min-width: 100%;
    padding: 24px 20px;
}
.input-price {
    width: 228px;
    height: 48px;
    margin-right: 16px;
    margin-top: 9px;
    border-radius: 5px;
    font-size: 14px;
}
form input.input-login {
    margin-bottom: 36px;
}
form input.input-default {
    margin-bottom: 16px;
}
.input-sharepoint_folder_description {
    height: 40px;
    margin: 0 !important;
}
.home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.home-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.home-cards-container .card {
    max-height: 255px !important;
    max-width: 310px !important;
}
.home .home-cards-container.caretaker .card > * {
    zoom: 0.65;
}
.card {
    width: 580px;
    height: 550px;
    border-radius: 25px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16px;
}

.card a {
    font-size: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-decoration: none;
    color: #000;
    width: 60%;
    text-align: center;
    cursor: pointer;
}

.card.shadow:hover {
    background: #fff;
    box-shadow: 0 3pt 6pt #00000029;
}

.icon.primary {
    margin-bottom: 50px;
}
.icon.preview {
    margin-bottom: 67px;
}
.header-default {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 48px;
    height: 91px;
    position: absolute;
    top: 0;
    width: auto;
    /*left: 440px;*/
    right: 0;
    border-bottom: 1px #707070 solid;
}
.step {
    color: #6A6A6A;
    font-size: 20px;
    font-weight: 600;
}
.header-title {
    font-size: 30px;
    font-weight: 600;
    color: #000;
}


.header-welcome {
    justify-content: flex-end;
    opacity: 0.85;
    border-bottom: 1px #fff solid;
}
.header-welcome .p-welcome {
    color: #fff;
    font-size: 20px;
}

.header-create-menu, .header-interactive {
    justify-content: space-between;
}

.header-double-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.header-double-action > div:first-child {
    margin-right: 18px;
}

.header-sharepoint-folder {
    justify-content: space-between;
}
.header-sharepoint-folder p.header-description {
    color: #0A0A0A;
    font-size: 16px;
}
textarea[disabled].input-sharepoint_folder_description {
    background-color: rgb(248, 248, 248);
    cursor: pointer;
}
.header-pwd {
    font-size: 16px;
    color: #0A0A0A;
    position: absolute;
    top: 70px;
}
.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #D30263;
    position: fixed;
    bottom: 0;
    height: 80px;
    width: auto;
    /*left: 390px;*/
    right: 0;
}

.footer > .progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.footer > .progress > .dot {
    background: #fff;
    opacity: 1;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin: 4px;
}
.footer > .progress > .dot.active {
    opacity: 0.50;
}
.footer a {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    min-width: 300px;
}
.footer a:first-child {
    margin-left: 42px;
    text-align: left;
    justify-content: flex-start;
}
.footer a:last-child {
    margin-right: 42px;
    text-align: right;
    justify-content: flex-end;
}
.footer a svg {
    margin: 0 8px;
    -webkit-transform: scale(1);
            transform: scale(1);
}



ul {
    padding: 12px;
}
ul li {
    display: flex;
}
.table {
    max-width: 100vw;
}
.table .row {
    display: flex;
    height: 125px;
    color: #000 !important;
}
.row > div, .row-header > div {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.row-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.row-item p {
    padding: 2px;
}
.row-item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}
.page-pool .body {
    align-items: flex-start;
}
.page-pool p {
    color: #fff !important;
}
.row-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #DCDBDC;
    right: 48px;
    /*left: 440px;*/
    position: absolute;
    top: 140px;
}
.row-header.row-header-has-filter {
    top: 200px;
}

.body.body-has-filter {
    margin-top: 240px;
}

.row-header.row-header-has-pagination {
    top: 200px;
}

.body.body-has-pagination {
    margin-top: 240px;
}
.row-header > div {
    padding-bottom: 8px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
}
.row-header.relative {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #DCDBDC;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
}
.row-header.relative div {
    font-size: 14px;
}

.body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 180px;
}
.row-bar, .row-leading-brand, .row-security, .row-date, .row-dimension, .row-unit, .row-package, .row-alergens {
    min-width: 200px;
}
.row-notification, .row-menu, .row-edit, .row-print {
    min-width: 40px;
    cursor: pointer;
}
.row-address, .row-name {
    min-width: 300px;
}
.row-beer-cider {
    min-width: 100px;
}
.row-menu-active.absolute {
    color: #008941;
    position: absolute;
    margin-left: -20px;
    margin-top: 3px;
}
.active-menu.label {
    position: absolute;
    top: -33px;
}
.active-menu.label span{
    color: #008941;
    padding: 0 4px;
}
.container-item-list {
    background: #fff;
    padding: 30px;
}
ul.item-list {
    position: absolute;
    /*left: 440px;*/
    right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}
ul.item-list li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 16px 0;
    font-size: 14px;
}
ul.item-list.categories li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
    font-size: 14px;
    padding-top: 20px;
    border-bottom: 1px solid #DCDBDC;
    margin: 0;
}
ul.item-list.relative {
    position: relative;
    left: 0;
    top: 12px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}
ul.item-list.relative li {
    font-size: 14px;
}
.row-bar, .row-name {
    font-weight: bold;
}
.row-edit .icon-edit-primary {
    stroke: #707070;
}

.search-filters {
    position: absolute;
    top: 91px;
    /*left: 440px;*/
    width: 20%;
    margin-top: 20px;
}
.products-list.search-filters {
    display: flex;
    flex-direction: row;
}
.products-list.search-filters > *:last-child {
    margin: 0 12px;
}
.products-list.search-filters .button-selection {
    margin: 0 8px;
}
.products-list.search-filters input {
    min-width: 250px;
    width: auto;
}

.pagination-container {
    position: absolute;
    top: 91px;
    /*left: 440px;*/
    width: 100%;
    margin-top: 20px;
    display: flex;
}
.pagination-container.pagination-container-bottom {
    width: 100%;
    display: flex;
    position: unset;
    margin-bottom: 20px;
}
.pagination-container .button-container {
    margin-right: 10px;
}
.pagination-container p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-size: 20px;
}

.pagination-container.pagination-container-has-filter {
    top: 180px;
}
.row-header.row-header-has-pagination.row-header-has-filter {
    top: 290px;
}
.body.body-has-pagination.body-has-filter {
    margin-top: 330px;
}


.modal-overlay {
    background: rgba(255, 255, 255, 0.75);
    opacity: 1;
    position: absolute;
    top: 0;
    /*left: 390px;*/
    width: 100%;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal {
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #F0F0F0;
    border-radius: 20px;
    opacity: 1;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 945px;
}

.modal .modal-header {
    border-bottom: 1px solid #0A0A0A;
    padding-bottom: 20px;
    margin-bottom: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.modal .modal-header p {
    font-size: 30px;
    font-weight: bold;
    color: #0A0A0A;
}
.modal .modal-header .icon-close {
    font-size: 44px;
    color: #707070;
}
.modal form {
    width: 100%;
}
.modal .input-default {
    width: 100%;
}

/* modal add product */
.modal-product .modal-body-split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.modal-product .modal-body-split form:first-child {
    padding-right: 16px;
    //margin-bottom: 16px !important;
}
.modal-product .modal-body-split form:last-child, .modal-product .modal-body-split form:nth-child(3) {
    padding-left: 16px;
//margin-bottom: 16px !important;
}

.modal-height {
    max-height: 95vh;
}
.modal-body {
    width: 100%;
    overflow-y: scroll;
}
.happy-hour-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
}
.happy-hour-container .checkbox-container {
    margin-top: 12px;
}

.react-timerange-picker__wrapper {
    border-radius: 4px;
    height: 50px;
}

/* PRODUCT PAGE */
.time-picker {
    margin-top: 8px;
    margin-right: 70px;
}
.time-picker .react-timerange-picker__wrapper {
    background-color: #ffffff;
}
.select-default {
    width: 100%;
    height: 56px;
    border-radius: 5px;
    font-size: 16px;
    color: #707070;
    margin-bottom: 70px;
}
.select-default option {
    font-size: 16px;
    padding-right: 10px;
    padding-left: 5px;
}
.select-default__control {
    height: 56px;
}
.select-default__control > div {
    height: 100%;
}
.select-default__control, .select-default__control:hover {
    border: none !important;
    box-shadow: none !important;
}
.select-default__option--is-selected, .select-default__option--is-focused.select-default__option--is-selected {
    border: none;
    background-color: #D30263 !important;
}
.select-default__option--is-focused {
    background-color: rgba(0, 137, 65, 0.08) !important;
}

.grey .select-default__control {
    background-color: #F0F0F0;
}

.select-default.alergens {
    margin-bottom: 33px;
}

.select-default.invalid-input .select-default__control {
    border: 1px solid red !important;
}
.select-default.alergens.alergens-edit {
    margin-bottom: 48px;
}




.notifications-list {
    position: relative;
    /*left: 440px;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin-top: 20px;
}

.page.notifications {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.page.notifications .body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 115px; /* header height + 25px margin */
}


.notification {
    width: 700px;
    height: auto;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 32px;
}
.notification-header {
    height: 50px;
    border-bottom: 1px solid #CAC9CA;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.notification-title, .notification-date, .notification-message, .notification-body a {
    font-size: 14px;
    margin: 0 20px;
}
.notification-title {
    color: #0A0A0A;
    font-weight: bold;
    padding-top: 8px;
}
.notification-date {
    color: #707070;
    padding-top: 8px;
}

.notification-body {
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.notification-body a {
    color: #707070;
}
.notification-message {
    color: #707070;
    margin: 20px;
}
.notification-body .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 35px;
    margin-right: 20px;
}

.page.menu-created .body svg {
    margin-bottom: 50px;
}
.page.menu-created .body p {
    text-align: center;
}
.menu-created-message {
    font-size: 30px;
    font-weight: bold;
    color: #0A0A0A;
    margin: 23px;
}
.menu-created-note {
    font-size: 14px;
    color: #707070;
    margin: 6px;
}
p.p-users-updated {
    font-size: 30px;
    font-weight: bold;
    color: #0A0A0A;
    margin: 33px;
    text-align: center;
}

.img-banner-container {
    padding-top: 20px;
    //position: relative;
    //left: 370px;
    //top: -148px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    object-fit: contain;
}
.img-banner-container .icon-close {
    font-size: 24px;
    fill: #707070;
    position: relative;
    right: 0px;
    top: -100px;
    cursor: pointer;
}
.img-banner-upload {
    max-width:380px;
}
.container-img-upload {
    position: relative;
    left: 370px;
    top: -148px;
    width: 150px;
    object-fit: contain;
}
.container-img-upload .icon-close {
    font-size: 24px;
    fill: #707070;
    position: relative;
    right: 10px;
    top: -100px;
    cursor: pointer;
}
/* default carbon components date picker */

.bx--date-picker {
    display: flex;
}

.bx--date-picker--light .bx--date-picker__input {
    background: #ffffff;
}

.bx--date-picker ~ .bx--label {
    order: 1;
}

.bx--date-picker-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bx--date-picker-input__wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.bx--date-picker.bx--date-picker--simple
.bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple .bx--label {
    width: 7.5rem;
}

.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--invalid .bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--warn .bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--warn ~ .bx--form-requirement {
    width: 9.5rem;
}

.bx--date-picker.bx--date-picker--simple.bx--date-picker--short .bx--date-picker__input {
    width: 5.7rem;
}

.bx--date-picker.bx--date-picker--single
.bx--date-picker__input {
    width: 18rem;
}

.bx--date-picker
.bx--date-picker-input__wrapper--warn
~ .bx--form-requirement {
    color: #161616;
}

.bx--date-picker__input {
    font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.32px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    position: relative;
    display: block;
    height: 2.5rem;
    padding: 0 1rem;
    color: #161616;
    background-color: #f4f4f4;
    border: none;
    border-bottom: 1px solid #8d8d8d;
    transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all;
}

.bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: 2px solid #D30263;
    outline-offset: -2px;
}

@media screen and (prefers-contrast) {
    .bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
        outline-style: dotted;
    }
}

.bx--date-picker__input:disabled {
    color: #c6c6c6;
    background-color: #f4f4f4;
    border-bottom: 1px solid transparent;
    cursor: not-allowed;
}

.bx--date-picker__input:disabled::-webkit-input-placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled:-ms-input-placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled::placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled:hover {
    border-bottom: 1px solid transparent;
}

.bx--date-picker__input::-webkit-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input:-ms-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input::placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input--xl {
    height: 3rem;
}

.bx--date-picker__input--sm {
    height: 2rem;
}

.bx--date-picker__icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    fill: #161616;
    width: 19px;
    height: 19px;
}

@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .bx--date-picker__icon {
        fill: ButtonText;
    }
}

.bx--date-picker__icon--invalid,
.bx--date-picker__icon--warn {
    cursor: auto;
}

.bx--date-picker__icon--warn {
    fill: #f1c21b;
}

.bx--date-picker__icon--warn path:first-of-type {
    opacity: 1;
    fill: #000000;
}

.bx--date-picker__icon--invalid {
    fill: #da1e28;
}

.bx--date-picker__icon ~ .bx--date-picker__input {
    padding-right: 3rem;
}

.bx--date-picker__input:disabled ~ .bx--date-picker__icon {
    cursor: not-allowed;
    fill: #c6c6c6;
}

.bx--date-picker--range
> .bx--date-picker-container:first-child {
    margin-right: 0.125rem;
}

.bx--date-picker--range .bx--date-picker-container,
.bx--date-picker--range .bx--date-picker__input {
    width: 8.96875rem;
}

.bx--date-picker.bx--skeleton input,
.bx--date-picker__input.bx--skeleton {
    position: relative;
    padding: 0;
    background: #e5e5e5;
    border: none;
    box-shadow: none;
    pointer-events: none;
    width: 100%;
}

.bx--date-picker.bx--skeleton input:hover, .bx--date-picker.bx--skeleton input:focus, .bx--date-picker.bx--skeleton input:active,
.bx--date-picker__input.bx--skeleton:hover,
.bx--date-picker__input.bx--skeleton:focus,
.bx--date-picker__input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default;
}

.bx--date-picker.bx--skeleton input::before,
.bx--date-picker__input.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
    animation: 3000ms ease-in-out skeleton infinite;
    content: '';
}

@media (prefers-reduced-motion: reduce) {
    .bx--date-picker.bx--skeleton input::before,
    .bx--date-picker__input.bx--skeleton::before {
        -webkit-animation: none;
        animation: none;
    }
}

.bx--date-picker.bx--skeleton input::-webkit-input-placeholder, .bx--date-picker__input.bx--skeleton::-webkit-input-placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton input:-ms-input-placeholder, .bx--date-picker__input.bx--skeleton:-ms-input-placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton input::placeholder,
.bx--date-picker__input.bx--skeleton::placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton .bx--label {
    position: relative;
    padding: 0;
    background: #e5e5e5;
    border: none;
    box-shadow: none;
    pointer-events: none;
    width: 4.6875rem;
    height: 0.875rem;
}

.bx--date-picker.bx--skeleton .bx--label:hover, .bx--date-picker.bx--skeleton .bx--label:focus, .bx--date-picker.bx--skeleton .bx--label:active {
    border: none;
    outline: none;
    cursor: default;
}

.bx--date-picker.bx--skeleton .bx--label::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
    animation: 3000ms ease-in-out skeleton infinite;
    content: '';
}

.bx--form-item {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
}


.bx--form-item.bx--checkbox-wrapper {
    margin-bottom: 0.25rem;
}

.bx--form-item.bx--checkbox-wrapper:first-of-type {
    margin-top: 0.1875rem;
}

.bx--label + .bx--form-item.bx--checkbox-wrapper {
    margin-top: -0.125rem;
}

.bx--form-item.bx--checkbox-wrapper:last-of-type {
    margin-bottom: 0.1875rem;
}


/* stylelint-disable */
.flatpickr-calendar {
    background: transparent;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    padding: 0;
    -webkit-animation: none;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 315px;
    box-sizing: border-box;
    touch-action: manipulation;
    background: #fff;
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
    opacity: 1;
    visibility: inherit;
    overflow: visible;
    max-height: 640px;
}

.flatpickr-calendar.open {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18rem;
    height: 21rem;
    padding: 0.25rem 0.25rem 0.5rem 0.25rem;
    border: none;
    margin-top: -2px;
    overflow: hidden;
    background-color: #f4f4f4;
}

.flatpickr-calendar.open:focus {
    outline: 1px solid #D30263;
}

@media screen and (prefers-contrast) {
    .flatpickr-calendar.open:focus {
        outline-style: dotted;
    }
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9);
    animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9);
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
}

.flatpickr-calendar.hasWeeks {
    width: auto;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
}

.flatpickr-calendar:focus {
    outline: 0;
}

.flatpickr-months {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.flatpickr-month {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    height: 2.5rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #161616;
    line-height: 1;
    text-align: center;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    line-height: 16px;
    height: 28px;
    padding: 10px calc(3.57% - 1.5px);
    z-index: 3;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    fill: #161616;
    transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
    background-color: #e5e5e5;
}

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
    fill: #161616;
}

.flatpickr-next-month.disabled svg,
.flatpickr-prev-month.disabled svg {
    fill: #161616;
    cursor: not-allowed;
}

.flatpickr-next-month.disabled:hover svg,
.flatpickr-prev-month.disabled:hover svg {
    fill: #161616;
}

.flatpickr-current-month {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.75rem;
    text-align: center;
}

.flatpickr-current-month .cur-month {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.flatpickr-current-month .cur-month:hover {
    background-color: #e5e5e5;
}

.numInputWrapper {
    position: relative;
    width: 3.75rem;
}

.numInputWrapper:hover {
    background-color: #e5e5e5;
}

.numInputWrapper:hover .arrowUp,
.numInputWrapper:hover .arrowDown {
    opacity: 1;
}

.numInputWrapper:hover .numInput[disabled] ~ .arrowUp,
.numInputWrapper:hover .numInput[disabled] ~ .arrowDown {
    opacity: 0;
}

.numInputWrapper .numInput {
    display: inline-block;
    width: 100%;
    padding: 0.25rem;
    margin: 0;
    border: none;
    color: #161616;
    font-size: inherit;
    font-family: inherit;
    font-weight: 600;
    background-color: #f4f4f4;
    cursor: default;
    -moz-appearance: textfield;
    /* Firefox */
}

.numInputWrapper .numInput:focus {
    outline: 1px solid #D30263;
}

@media screen and (prefers-contrast) {
    .numInputWrapper .numInput:focus {
        outline-style: dotted;
    }
}

.numInputWrapper .numInput[disabled], .numInputWrapper .numInput[disabled]:hover {
    color: #c6c6c6;
    background-color: #f4f4f4;
    pointer-events: none;
}

.numInputWrapper .numInput::-webkit-outer-spin-button, .numInputWrapper .numInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.numInput[disabled] ~ .arrowUp::after {
    border-bottom-color: #c6c6c6;
}

.numInput[disabled] ~ .arrowDown::after {
    border-top-color: #c6c6c6;
}

.numInputWrapper .arrowUp,
.numInputWrapper .arrowDown {
    position: absolute;
    left: 2.6rem;
    width: 0.75rem;
    height: 50%;
    border: none;
    padding: 0 0.25rem 0 0.125rem;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
}

.numInputWrapper .arrowUp::after,
.numInputWrapper .arrowDown::after {
    position: absolute;
    top: 33%;
    display: block;
    content: '';
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
}

.numInputWrapper .arrowUp:hover::after,
.numInputWrapper .arrowDown:hover::after {
    border-bottom-color: #D30263;
    border-top-color: #D30263;
}

.numInputWrapper .arrowUp:active::after,
.numInputWrapper .arrowDown:active::after {
    border-bottom-color: #D30263;
    border-top-color: #D30263;
}

.numInputWrapper .arrowUp {
    top: 0.25rem;
    border-bottom: 0;
}

.numInputWrapper .arrowUp::after {
    border-bottom: 0.25rem solid #161616;
}

.numInputWrapper .arrowDown {
    top: 0.6875rem;
}

.numInputWrapper .arrowDown::after {
    border-top: 0.25rem solid #161616;
}

.flatpickr-weekdays {
    display: flex;
    align-items: center;
    height: 2.5rem;
}

.flatpickr-weekdaycontainer {
    display: flex;
    width: 100%;
}

.flatpickr-weekday {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    cursor: default;
    color: #161616;
    flex: 1 1;
}

.flatpickr-days:focus {
    outline: 0;
}

.dayContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 15.375rem;
    padding: 0;
    outline: 0;
}

.flatpickr-calendar.animate .dayContainer.slideLeft {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
}

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideRight {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
}

.flatpickr-calendar.animate .dayContainer.slideRightNew {
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-day {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #161616;
    cursor: pointer;
    transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.flatpickr-day:hover {
    background: #e5e5e5;
}

.flatpickr-day:focus {
    outline: 2px solid #D30263;
    outline-offset: -2px;
    outline-color: #D30263;
}

@media screen and (prefers-contrast) {
    .flatpickr-day:focus {
        outline-style: dotted;
    }
}

.nextMonthDay,
.prevMonthDay {
    color: #6f6f6f;
}

.flatpickr-day.today {
    position: relative;
    color: #D30263;
    font-weight: 600;
}

.flatpickr-day.today::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0.4375rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 0.25rem;
    width: 0.25rem;
    background-color: #D30263;
}

.flatpickr-day.today.no-border {
    border: none;
}

.flatpickr-day.today.selected {
    outline: 2px solid #D30263;
    outline-offset: -2px;
}

@media screen and (prefers-contrast) {
    .flatpickr-day.today.selected {
        outline-style: dotted;
    }
}

.flatpickr-day.today.selected::after {
    display: none;
}

.flatpickr-day.inRange {
    background-color: #d0e2ff;
    color: #161616;
}

.flatpickr-day.selected {
    color: #ffffff;
    background-color: #D30263;
}

.flatpickr-day.selected:focus {
    outline: 0.0625rem solid #ffffff;
    outline-offset: -0.1875rem;
}

.flatpickr-day.startRange.selected {
    box-shadow: none;
    z-index: 2;
}

.flatpickr-day.startRange.inRange:not(.selected),
.flatpickr-day.endRange.inRange {
    outline: 2px solid #D30263;
    outline-offset: -2px;
    background: #f4f4f4;
    z-index: 3;
}

@media screen and (prefers-contrast) {
    .flatpickr-day.startRange.inRange:not(.selected),
    .flatpickr-day.endRange.inRange {
        outline-style: dotted;
    }
}

.flatpickr-day.endRange:hover {
    outline: 2px solid #D30263;
    outline-offset: -2px;
    background: #f4f4f4;
    color: #161616;
}

@media screen and (prefers-contrast) {
    .flatpickr-day.endRange:hover {
        outline-style: dotted;
    }
}

.flatpickr-day.endRange.inRange.selected {
    color: #ffffff;
    background: #D30263;
}

.flatpickr-day.flatpickr-disabled {
    cursor: not-allowed;
    color: #c6c6c6;
}

.flatpickr-day.flatpickr-disabled:hover {
    background-color: transparent;
}

.flatpickr-input[readonly] {
    cursor: pointer;
}

/* custom override styling*/
.bx--form-item div, .bx--form-item input {
    width: 100% !important;
}
.bx--form-item input {
    background: #fff;
    border-bottom: none;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    height: 56px;
    color: #707070;
}
.bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: 1px solid #D30263;
}
.container-background-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 32px;
}
.wrapper-background-image {
    height: 388px;
    width: 280px;
    background: #fff;
    margin-right: 32px;
    cursor: pointer;
}
.wrapper-background-image.selected {
    border: 5px solid #D30263;
    border-radius: 13px;
    width: 280px;
    background: #fff;
}
.wrapper-background-image.selected svg {
    width: 42px;
    height: 42px;
    position: absolute;
    margin-left: 210px;
    margin-top: -20px;
    fill: #D30263;
}

/* BEER & CIDER */
.container-background-image.beer-cider .wrapper-background-image {
    background-size: cover !important;
    height: 200px;
    width: 284px;
    margin: 15px;
}

.container-background-image.beer-cider {
    flex-grow: initial;
    flex-wrap: wrap;
    padding-bottom: 300px;
}
.main.normal .page {
    height: 100vh !important;
}



.page.edit-menu-add-products {
    min-height: 100vh;
    height: 100% !important;
    justify-content: stretch;
}
.page.edit-menu-add-products .body {
    padding-right: 50px !important;
    margin-top: 120px;
    padding-top: 25px;
}
.masonry-create-menu-categories-pool {
    margin-top: 14px;
    padding-bottom: 220px;
}
.create-menu-category-pool-item, .create-menu-category-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-radius: 20px;
    background-color: #fff;
}
.create-menu-category-pool-item:active, .create-menu-category-pool-item.selected, .create-menu-category-item:active {
    border: 3px solid #D30263;
}
.container-icon-selected {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: self-start;
    height: 100%;
    margin-top: -20px;
    margin-right: 20px;
}
.create-menu-category-item .container-icon-selected {
    align-items: center;
    margin-top: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.create-menu-category-item .container-icon-selected svg {
    visibility: visible;
    fill: #707070;
    width: 31px;
    height: 31px;
}

.create-menu-category-pool-item svg {
    visibility: hidden;
}
.create-menu-category-pool-item.selected svg {
    visibility: visible;
    fill: #D30263;
    width: 31px;
    height: 31px;
}
.create-menu-category-pool-item .p-title-product {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.create-menu-category-pool-item:hover, .create-menu-category-item:hover {
    cursor: grab;
}

.create-menu-category-pool-item .p-title-product, .create-menu-category-item .p-title-product {
    padding-left: 35px;
}
.create-menu-category-item {
    margin-bottom: 8px;
    box-shadow: 0px 3px 6px #00000029;
}

.button-add-category {
    opacity: 0.5;
}
.button-add-category:hover {
    opacity: 1;
    background: #D30263;
}
.button-add-category:hover .button p {
    color: #fff;
}

.selected-category-drop-zone {

}
.drop-zone-category-pool {}


/* locked category item */
.create-menu-category-item.category.locked {
    background: #e9e9e9;
}
/*
.create-menu-category-item.category.locked:hover {
    cursor: default;
}
.create-menu-category-item.category.locked:active {
    border: none;
}

 */

.page.edit-menu {
    height: 100% !important;
}
.page.edit-menu .body {
    padding-right: 50px !important;
}
.masonry-edit-menu {
    margin-top: 25px;
    padding-bottom: 220px;
}
.menu-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}
.menu-category-header {
    background: #fff;
    border-bottom: 1px solid #707070;
    height: 45px;
    padding-left: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
}
.menu-category-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.menu-category-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 35px;
}
.menu-category-product:not(:last-child) {
    border-bottom: 0.25pt solid #c3c3c3;
}



.menu-category-product-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.menu-category-product-left-desc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.menu-category-product-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.p-title-category {
    padding-top: 20px;
}
.p-title-product {
    margin-top: 10px;
}
.p-desc-product {
    margin-right: 4px;
}

.page.edit-menu-add-products {
    min-height: 100vh;
    height: 100% !important;
    justify-content: stretch;
}
.page.edit-menu-add-products .body {
    padding-right: 50px !important;
    margin-top: 120px;
    padding-top: 25px;
}
.masonry-create-menu-categories-pool {
    margin-top: 14px;
    padding-bottom: 220px;
}

.create-menu-product-pool-item svg {
    visibility: hidden;
}
.create-menu-product-pool-item.selected svg {
    visibility: visible;
    fill: #D30263;
    width: 31px;
    height: 31px;
}
.create-menu-product-pool-item .p-title-product {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.create-menu-category-item.product, .create-menu-category-item.category {
    margin-bottom: 8px;
    box-shadow: 0px 3px 6px #00000029;
}


.button-add-category {
    opacity: 0.5;
}
.button-add-category:hover {
    opacity: 1;
    background: #D30263;
}
.button-add-category:hover .button p {
    color: #fff;
}

.selected-product-drop-zone {

}
.drop-zone-product-pool {
}


/* ADD PRODUCTS */
.container-column-2 {
    padding-bottom: 220px;
}
.create-menu-product-pool-item:hover {
    cursor: pointer;
}
.create-menu-category-item-product:hover {
    cursor: grab !important;
}
.create-menu-product-pool-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-radius: 20px;
    background-color: #fff;
}
.create-menu-category-item.product, .create-menu-category-item.combo-offer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 20px;
    background-color: #fff;
    border: none;
    cursor: auto !important;
}
.create-menu-product-pool-item:active, .create-menu-product-pool-item.selected, .create-menu-category-item-product:active {
    border: 3px solid #D30263;
}
.create-menu-category-item-product:active {
/* border-radius: 12 px; */
}
.create-menu-category-item.product:active {
    border: none !important;
}
.create-menu-category-item-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}
.create-menu-category-item-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /*width: 96%;*/
    height: 78px;
    border-top: 0.25pt solid #707070;
}
.create-menu-category-item-product input {
    margin: 0 !important;
    height: 48px;
    margin-right: 80px !important;
}
.create-menu-product-pool-item .p-title-product, .create-menu-category-item .p-title-product {
    padding-left: 35px;
}
.create-menu-category-item.product .p-title-product, .create-menu-category-item.combo-offer .p-title-product  {
    padding-left: 22px
}
.create-menu-category-item.product .p-title-product.black {
    color: #0A0A0A;
}



.container-icon-selected {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: self-start;
    height: 100%;
    margin-top: -20px;
    margin-right: 25px;
    margin-left: 25px;
    position: relative;
}
.create-menu-product-pool-item .container-icon-selected.products-list-selection {
    width: 40px;
}
.create-menu-category-item.product .container-icon-selected, .create-menu-category-item.combo-offer .container-icon-selected {
    align-items: center;
    margin-top: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.create-menu-category-item.product .container-icon-selected svg, .create-menu-category-item.combo-offer .container-icon-selected svg {
    visibility: visible;
    fill: #707070;
    width: 31px;
    height: 31px;
}
.create-menu-category-item-product-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.create-menu-category-item-product-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.create-menu-category-item-product-left-desc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 21px;
}

/* beer & cider */
.create-menu-category-item-product.beer-cider .create-menu-category-item-product-right > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.create-menu-category-item-product.beer-cider .create-menu-category-item-product-right > div form {
    padding: 6px;
    max-width: 90px;
}
.create-menu-category-item-product.beer-cider .create-menu-category-item-product-right > div .p-desc-product {
    padding-bottom: 0;
    min-width: -webkit-max-content;
    min-width: max-content;
}



/* combo offers */
.create-menu-product-pool-item.combo-offer-pool-item {
    height: 80px;
}

.create-menu-product-pool-item.combo-offer-pool-item img {
    position: relative;
    height: 76px;
    object-fit: contain;
    width: auto;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.create-menu-product-pool-item.combo-offer-pool-item .p-title-product, .create-menu-product-pool-item.combo-offer-pool-item .p-title-product.desc{
    min-width: 40% !important;
}
.create-menu-product-pool-item.combo-offer-pool-item .p-title-product.desc {
    font-size: 12px;
    font-weight: normal;
}
.create-menu-category-item .p-title-product {
    color: #0A0A0A;
}
.create-menu-category-item.active .p-title-product.category {
    color: #D30263;
}

.add-products-masonry-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.add-products-masonry-header > div  {
    padding-right: 12px;
}

.add-products-masonry-header > form:nth-child(2)  {
    padding-left: 12px;
}

.add-products-masonry-header form {
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.add-products-masonry-header form .p-title-small {
    padding: 0 !important;
}

.add-products-masonry-header form .input-label > .select-default {
    margin: 0 !important;
}


.create-menu-product-pool-item-left {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.create-menu-product-pool-item-right {
    display: flex;
    flex-direction: row;
    margin-right: -40px;
    justify-content: space-between;
    align-items: center;
}

.create-menu-category-item.product.active, .create-menu-category-item.combo-offer.active {
    cursor: pointer;
}

/* AG CHANGES */

.create-menu-category-item-product-right form {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 200px;
}
.create-menu-category-item-product-right > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    max-height: 56px;
}
.create-menu-category-item-product-right > div > form:first-child {
    margin-top: -16px;
    zoom: 0.80;
    padding-right: 8px;
}
.background.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* z-index: 999;

     */
}
.background.fixed::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
}
.background.fixed.template-0::before {
    background: radial-gradient(circle at 50% 50%, rgba(51, 63, 72, 0.96), rgba(23, 34, 41, 1));
}
.background.fixed.template-1::before {
    background: radial-gradient(circle at 50% 50%, rgba(226, 226, 226, 0.96), rgb(152, 152, 152));
}

.online-menu-category {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    width: 100%;
}
.online-menu-title-category {
    line-height: 22px;
    font-size: 14px;
    color: #0A0A0A;
    text-align: left;
    font-weight: bolder;
    padding: 10px 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.online-menu-item-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}
.online-menu-item-product {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.online-menu-item-product.no-label {
    padding-right: 27px;
}

.online-menu-item-product > .online-menu-item-product-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 4px 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 30px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0px #00000080;
    margin-bottom: 12px;
}
.online-menu-item-product.combo-offer {
    width: 100%;
}
.online-menu-item-label {
    right: 0;
    background: #fff;
    padding: 4px 8px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    width: 110px;
    font-size: 12px;
    color: #fff;
}

.online-menu-item-product-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 77%;
    padding-left: 12px;
}
.online-menu-item-product-left-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.online-menu-title-product {
    line-height: 22px;
    letter-spacing: 1.2px;
    font-size: 13px;
    text-align: left;
    color: #fff;
}
.online-menu-alergens-product {
    line-height: 12px;
    letter-spacing: 0.85px;
    font-size: 9px;
    text-align: left;
    color: #fff;
    margin: 0 6px;
}
.online-menu-unit-product {
    line-height: 22px;
    letter-spacing: 0.85px;
    font-size: 12px;
    text-align: left;
    color: #fff;
}
.online-menu-item-product-right {
    padding-right: 12px;
}
.online-menu-price-product {
    line-height: 22px;
    letter-spacing: 1.2px;
    font-size: 13px;
    text-align: left;
    color: #fff;
}
.Collapsible {
    width: 100% !important;
    margin: 2px 0;
}
.collapsible-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /*border-bottom: 2px solid rgba(255, 255, 255, 0.42);
     */
}
.collapsible-header.l10n-options {
    justify-content: flex-end;
    border: none;
}
.collapsible-header.l10n-options > div {
    padding: 0 8px;
}
.collapsible-header.l10n-options svg {
    color: rgba(255, 255, 255, 0.45) !important;
}
.l10n-options-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 32px 14px 32px;
}

.collapsible-menu-listing {

}
.online-menu-text-footer {
    line-height: 16px;
    letter-spacing: 0;
    font-size: 11px;
    text-align: center;
    color: #fff;
    font-weight: lighter;
    margin: 30px 0;
    margin-top: 60px;
    bottom: 0;
}

.online-menu-text-footer.bar-info {
    margin-top: 0;
    margin: 30px 0;
    bottom: 0;
}

.online-menu-text-footer.alergens {
    margin: 10px 0;
    bottom: 0;
}

/* BARCAFFE DIGITAL MENU */

.logo-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
}
.logo-row > img {
    min-height: 8vh;
    object-fit: contain;
}
.logo-row > img:first-child {
    max-width: 40%;
    min-width: 80px;
}
.logo-row > .logo-row-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.logo-row > .logo-row-inner > img {
    padding: 0;
    max-height: 19vw;
}
.categories-horizontal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: visible;
    min-width: 100%;
    max-width: 100vw;
    min-height: 100px;
    max-height: 100px;
    margin-bottom: 16px;
    position: relative;
    padding-left: 27px !important;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}
.categories-horizontal .category-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.categories-horizontal .category-container {
    min-height: 60px;
    max-height: 60px;
    min-width: 100px;
    max-width: 100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-items: center;
    align-items: center;
}
.template-0 .categories-horizontal .category-container {
    background-color: transparent;
}
.template-1 .categories-horizontal .category-container {
    background-color: rgba(255, 255, 255, 0.62);
}
.categories-horizontal .category-container > img {
    object-fit: contain;
    height: 34px;
    width: 100%;
}
.categories-horizontal > *:not(:last-child) {
    padding-right: 12px
}
.categories-horizontal::-webkit-scrollbar {
    display: none;
}
/*
.page.digital-menu .body {
    padding: 27px;
}
 */
.category-item {
    height: 100%;
}
.footer-digital-menu {
    background-size: contain;
    background-repeat: repeat-x;
    background-position-y: top;
    background-position-x: center;
    width: 100vw;
    margin-left: -27px;
    margin-top: 27px;
}
.footer-digital-menu > div {
    width: 100%;
    margin-top: 27px;
    padding: 27px;
    padding-top: 0;
    background-color: transparent;
}
.footer-digital-menu > div:last-child > p:last-child {
    margin-bottom: 0;
}
.footer-digital-menu > .logo-row img {
    max-width: 30%;
    min-height: 0;
    margin: auto;
    object-fit: contain;
}
.footer-digital-menu > .logo-row {
    padding: 0 27px;
}
.online-menu-category-line {
    width: 100%;
    border-color: #fff;
    margin-right: 27px;
}

/* VOTES */

.online-menu-score {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 0px;
    justify-content: space-between;
    padding-bottom: 190px;

}
.online-menu-item-score {
    background: rgba(255, 255, 255, 0.2);
    min-width: 60px;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.online-menu-item-score p {
    color: #fff;
}
.online-menu-item-product-container.questionnaire {
    background-color: rgba(255, 255, 255, 0.2);
}
.online-menu-item-score.selected, .online-menu-item-product-container.questionnaire.selected {
    background-color: #fff;
    color: #fff;
}
.footer-digital-menu.questionnaire {
    position: fixed;
    bottom: 0;
}
.questionnaire.main-title, .questionnaire.title {
    font-size: 13pt;
    padding: 20px 0;
    font-family: "Poppins SemiBold", Poppins, sans-serif;
    font-weight: 500;
    line-height: 1.4;
}
.questionnaire.title {
    color: #fff;
    display: inline-grid;
}
.questionnaire.main-title {
    font-size: 13pt;
    color: #fff;
    font-weight: 600;
}
.questionnaire.divider {
    width: 100%;
    border: 1px solid #fff;
    border-top: 0;
}
.questionnaire.background.fixed::before {
    background: linear-gradient(180deg, rgb(23, 24, 26) 0%, rgba(255,255,255,0) 40%);;
    -webkit-backdrop-filter: none;;
            backdrop-filter: none;
}
.questionnaire .footer-digital-menu > div {
    padding-bottom: 15px;
}
.questionnaire.footer-digital-menu .logo-row {
    margin-bottom: -20px;
}
.questionnaire.footer-digital-menu .online-menu-text-footer.bar-info {
    margin: 0;
}
.questionnaire .form-container form input  { /* Edge */
    color: white;
    font-size: 15pt;
}
.questionnaire .form-container   { /* Edge */
    padding-top: 60px;
}
.questionnaire .placeholder {
    position: relative;
    margin-bottom: -15px;
    height: 0;
    font-size: 15pt;
    color: white;
    font-family: Poppins, sans-serif;
}
.questionnaire .input-loadUserActivation {
    margin-bottom: 36px;
    background: transparent;
    border-bottom: 1px solid rgb(224, 0, 118);
    padding-left: 0px;
    border-radius: 0;
}
.questionnaire .checkbox-container {
    color: #fff;
    font-size: 9pt;
}

.questionnaire input[type='checkbox'] {
    margin: 0 14px;
    height: 30px;
    width: 35px;
    outline: none;
    -webkit-appearance: none;
            appearance: none;
    border: 1px solid white;
    border-radius: 5px;
    align-self: self-start;
}
.questionnaire .checkbox-container span {
    font-family: "Poppins-Light", "Poppins";
    font-weight: 100;
    font-size: 8pt;
}

p.icons-category {
    padding: 0 12px;
}
.category-icons-container {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.category-icons-container img {
    max-height: 40px;
    object-fit: contain;
}
.category-icons {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}
.icon-category-container {
    align-self: center;
    height: 60px;
    width: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    margin: 8px 4px;
    cursor: pointer;
}
.icon-category-container.selected {
    border: 2px solid #fff;
}
.page.ad {
    //height: fit-content;
    //min-height: 100vh;
}
.remove-bar-icon svg, .img-banner-container svg path {
    fill: #D01829;
}
.add-bars-to-ad-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: -60px;
    text-decoration: underline;
}
.add-bars-to-ad-container a {
    margin: 10px 12px;
}
.add-bars-to-ad-container a.remove-bars:hover {
    color: #D01829;
    font-weight: bold;
}
.add-bars-to-ad-container a.add-bars:hover {
    color: #000;
    font-weight: bold;
}
.page.ad .container-column-2 {
    padding-bottom: 0px !important;
}
.ad-bars-list {
    margin-top: -36px;
    margin-bottom: 20px;
}
.container-icon-selected {
    margin-top: -25px;
}

/* live ad banner display */

/*
.ad-banner-container {

}
.ad-banner {

}

 */
.collapsible-ad-banner {
    margin: auto;
    padding: 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw !important;
    z-index: 9999;
}


.collapsible-ad-banner div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.collapsible-ad-banner .banner {
    max-height: 20vh;
    object-fit: cover;
}


/*

<div class="wrapper">
    <img id="slide" src="https://cdn.xl.thumbs.canstockphoto.com/-drawings_csp14518596.jpg" />
</div>

*/
