.card {
    width: 580px;
    height: 550px;
    border-radius: 25px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16px;
}

.card a {
    font-size: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-decoration: none;
    color: #000;
    width: 60%;
    text-align: center;
    cursor: pointer;
}

.card.shadow:hover {
    background: #fff;
    box-shadow: 0 3pt 6pt #00000029;
}

.icon.primary {
    margin-bottom: 50px;
}
.icon.preview {
    margin-bottom: 67px;
}