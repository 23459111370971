.notifications-list {
    position: relative;
    /*left: 440px;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin-top: 20px;
}

.page.notifications {
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.page.notifications .body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 115px; /* header height + 25px margin */
}

