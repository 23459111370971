.page.edit-menu-add-products {
    min-height: 100vh;
    height: 100% !important;
    justify-content: stretch;
}
.page.edit-menu-add-products .body {
    padding-right: 50px !important;
    margin-top: 120px;
    padding-top: 25px;
}
.masonry-create-menu-categories-pool {
    margin-top: 14px;
    padding-bottom: 220px;
}

.create-menu-product-pool-item svg {
    visibility: hidden;
}
.create-menu-product-pool-item.selected svg {
    visibility: visible;
    fill: #D30263;
    width: 31px;
    height: 31px;
}
.create-menu-product-pool-item .p-title-product {
    min-width: fit-content;
}

.create-menu-category-item.product, .create-menu-category-item.category {
    margin-bottom: 8px;
    box-shadow: 0px 3px 6px #00000029;
}


.button-add-category {
    opacity: 0.5;
}
.button-add-category:hover {
    opacity: 1;
    background: #D30263;
}
.button-add-category:hover .button p {
    color: #fff;
}

.selected-product-drop-zone {

}
.drop-zone-product-pool {
}


/* ADD PRODUCTS */
.container-column-2 {
    padding-bottom: 220px;
}
.create-menu-product-pool-item:hover {
    cursor: pointer;
}
.create-menu-category-item-product:hover {
    cursor: grab !important;
}
.create-menu-product-pool-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-radius: 20px;
    background-color: #fff;
}
.create-menu-category-item.product, .create-menu-category-item.combo-offer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: fit-content;
    border-radius: 20px;
    background-color: #fff;
    border: none;
    cursor: auto !important;
}
.create-menu-product-pool-item:active, .create-menu-product-pool-item.selected, .create-menu-category-item-product:active {
    border: 3px solid #D30263;
}
.create-menu-category-item-product:active {
/* border-radius: 12 px; */
}
.create-menu-category-item.product:active {
    border: none !important;
}
.create-menu-category-item-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    width: 100%;
}
.create-menu-category-item-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /*width: 96%;*/
    height: 78px;
    border-top: 0.25pt solid #707070;
}
.create-menu-category-item-product input {
    margin: 0 !important;
    height: 48px;
    margin-right: 80px !important;
}
.create-menu-product-pool-item .p-title-product, .create-menu-category-item .p-title-product {
    padding-left: 35px;
}
.create-menu-category-item.product .p-title-product, .create-menu-category-item.combo-offer .p-title-product  {
    padding-left: 22px
}
.create-menu-category-item.product .p-title-product.black {
    color: #0A0A0A;
}



.container-icon-selected {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: self-start;
    height: 100%;
    margin-top: -20px;
    margin-right: 25px;
    margin-left: 25px;
    position: relative;
}
.create-menu-product-pool-item .container-icon-selected.products-list-selection {
    width: 40px;
}
.create-menu-category-item.product .container-icon-selected, .create-menu-category-item.combo-offer .container-icon-selected {
    align-items: center;
    margin-top: 0;
    width: fit-content;
}
.create-menu-category-item.product .container-icon-selected svg, .create-menu-category-item.combo-offer .container-icon-selected svg {
    visibility: visible;
    fill: #707070;
    width: 31px;
    height: 31px;
}
.create-menu-category-item-product-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.create-menu-category-item-product-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.create-menu-category-item-product-left-desc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 21px;
}

/* beer & cider */
.create-menu-category-item-product.beer-cider .create-menu-category-item-product-right > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.create-menu-category-item-product.beer-cider .create-menu-category-item-product-right > div form {
    padding: 6px;
    max-width: 90px;
}
.create-menu-category-item-product.beer-cider .create-menu-category-item-product-right > div .p-desc-product {
    padding-bottom: 0;
    min-width: max-content;
}



/* combo offers */
.create-menu-product-pool-item.combo-offer-pool-item {
    height: 80px;
}

.create-menu-product-pool-item.combo-offer-pool-item img {
    position: relative;
    height: 76px;
    object-fit: contain;
    width: auto;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.create-menu-product-pool-item.combo-offer-pool-item .p-title-product, .create-menu-product-pool-item.combo-offer-pool-item .p-title-product.desc{
    min-width: 40% !important;
}
.create-menu-product-pool-item.combo-offer-pool-item .p-title-product.desc {
    font-size: 12px;
    font-weight: normal;
}
.create-menu-category-item .p-title-product {
    color: #0A0A0A;
}
.create-menu-category-item.active .p-title-product.category {
    color: #D30263;
}

.add-products-masonry-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.add-products-masonry-header > div  {
    padding-right: 12px;
}

.add-products-masonry-header > form:nth-child(2)  {
    padding-left: 12px;
}

.add-products-masonry-header form {
    width: 50%;
    height: fit-content;
}

.add-products-masonry-header form .p-title-small {
    padding: 0 !important;
}

.add-products-masonry-header form .input-label > .select-default {
    margin: 0 !important;
}


.create-menu-product-pool-item-left {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.create-menu-product-pool-item-right {
    display: flex;
    flex-direction: row;
    margin-right: -40px;
    justify-content: space-between;
    align-items: center;
}

.create-menu-category-item.product.active, .create-menu-category-item.combo-offer.active {
    cursor: pointer;
}

/* AG CHANGES */

.create-menu-category-item-product-right form {
    width: fit-content;
    max-width: 200px;
}
.create-menu-category-item-product-right > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    max-height: 56px;
}
.create-menu-category-item-product-right > div > form:first-child {
    margin-top: -16px;
    zoom: 0.80;
    padding-right: 8px;
}