.img-banner-container {
    padding-top: 20px;
    //position: relative;
    //left: 370px;
    //top: -148px;
    width: fit-content;
    object-fit: contain;
}
.img-banner-container .icon-close {
    font-size: 24px;
    fill: #707070;
    position: relative;
    right: 0px;
    top: -100px;
    cursor: pointer;
}
.img-banner-upload {
    max-width:380px;
}