.header-default {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 48px;
    height: 91px;
    position: absolute;
    top: 0;
    width: auto;
    /*left: 440px;*/
    right: 0;
    border-bottom: 1px #707070 solid;
}
.step {
    color: #6A6A6A;
    font-size: 20px;
    font-weight: 600;
}
.header-title {
    font-size: 30px;
    font-weight: 600;
    color: #000;
}


.header-welcome {
    justify-content: flex-end;
    opacity: 0.85;
    border-bottom: 1px #fff solid;
}
.header-welcome .p-welcome {
    color: #fff;
    font-size: 20px;
}

.header-create-menu, .header-interactive {
    justify-content: space-between;
}

.header-double-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.header-double-action > div:first-child {
    margin-right: 18px;
}

.header-sharepoint-folder {
    justify-content: space-between;
}
.header-sharepoint-folder p.header-description {
    color: #0A0A0A;
    font-size: 16px;
}
textarea[disabled].input-sharepoint_folder_description {
    background-color: rgb(248, 248, 248);
    cursor: pointer;
}
.header-pwd {
    font-size: 16px;
    color: #0A0A0A;
    position: absolute;
    top: 70px;
}