.home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.home-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.home-cards-container .card {
    max-height: 255px !important;
    max-width: 310px !important;
}
.home .home-cards-container.caretaker .card > * {
    zoom: 0.65;
}