.notification {
    width: 700px;
    height: auto;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 32px;
}
.notification-header {
    height: 50px;
    border-bottom: 1px solid #CAC9CA;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.notification-title, .notification-date, .notification-message, .notification-body a {
    font-size: 14px;
    margin: 0 20px;
}
.notification-title {
    color: #0A0A0A;
    font-weight: bold;
    padding-top: 8px;
}
.notification-date {
    color: #707070;
    padding-top: 8px;
}

.notification-body {
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.notification-body a {
    color: #707070;
}
.notification-message {
    color: #707070;
    margin: 20px;
}
.notification-body .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 35px;
    margin-right: 20px;
}
