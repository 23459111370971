.background.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* z-index: 999;

     */
}
.background.fixed::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    backdrop-filter: blur(1px);
}
.background.fixed.template-0::before {
    background: radial-gradient(circle at 50% 50%, rgba(51, 63, 72, 0.96), rgba(23, 34, 41, 1));
}
.background.fixed.template-1::before {
    background: radial-gradient(circle at 50% 50%, rgba(226, 226, 226, 0.96), rgb(152, 152, 152));
}

.online-menu-category {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    width: 100%;
}
.online-menu-title-category {
    line-height: 22px;
    font-size: 14px;
    color: #0A0A0A;
    text-align: left;
    font-weight: bolder;
    padding: 10px 12px;
    width: fit-content;
}
.online-menu-item-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}
.online-menu-item-product {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.online-menu-item-product.no-label {
    padding-right: 27px;
}

.online-menu-item-product > .online-menu-item-product-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 4px 0;
    height: fit-content;
    min-height: 30px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0px #00000080;
    margin-bottom: 12px;
}
.online-menu-item-product.combo-offer {
    width: 100%;
}
.online-menu-item-label {
    right: 0;
    background: #fff;
    padding: 4px 8px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    width: 110px;
    font-size: 12px;
    color: #fff;
}

.online-menu-item-product-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 77%;
    padding-left: 12px;
}
.online-menu-item-product-left-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.online-menu-title-product {
    line-height: 22px;
    letter-spacing: 1.2px;
    font-size: 13px;
    text-align: left;
    color: #fff;
}
.online-menu-alergens-product {
    line-height: 12px;
    letter-spacing: 0.85px;
    font-size: 9px;
    text-align: left;
    color: #fff;
    margin: 0 6px;
}
.online-menu-unit-product {
    line-height: 22px;
    letter-spacing: 0.85px;
    font-size: 12px;
    text-align: left;
    color: #fff;
}
.online-menu-item-product-right {
    padding-right: 12px;
}
.online-menu-price-product {
    line-height: 22px;
    letter-spacing: 1.2px;
    font-size: 13px;
    text-align: left;
    color: #fff;
}
.Collapsible {
    width: 100% !important;
    margin: 2px 0;
}
.collapsible-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /*border-bottom: 2px solid rgba(255, 255, 255, 0.42);
     */
}
.collapsible-header.l10n-options {
    justify-content: flex-end;
    border: none;
}
.collapsible-header.l10n-options > div {
    padding: 0 8px;
}
.collapsible-header.l10n-options svg {
    color: rgba(255, 255, 255, 0.45) !important;
}
.l10n-options-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 32px 14px 32px;
}

.collapsible-menu-listing {

}
.online-menu-text-footer {
    line-height: 16px;
    letter-spacing: 0;
    font-size: 11px;
    text-align: center;
    color: #fff;
    font-weight: lighter;
    margin: 30px 0;
    margin-top: 60px;
    bottom: 0;
}

.online-menu-text-footer.bar-info {
    margin-top: 0;
    margin: 30px 0;
    bottom: 0;
}

.online-menu-text-footer.alergens {
    margin: 10px 0;
    bottom: 0;
}

/* BARCAFFE DIGITAL MENU */

.logo-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
}
.logo-row > img {
    min-height: 8vh;
    object-fit: contain;
}
.logo-row > img:first-child {
    max-width: 40%;
    min-width: 80px;
}
.logo-row > .logo-row-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.logo-row > .logo-row-inner > img {
    padding: 0;
    max-height: 19vw;
}
.categories-horizontal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: visible;
    min-width: 100%;
    max-width: 100vw;
    min-height: 100px;
    max-height: 100px;
    margin-bottom: 16px;
    position: relative;
    padding-left: 27px !important;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}
.categories-horizontal .category-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.categories-horizontal .category-container {
    min-height: 60px;
    max-height: 60px;
    min-width: 100px;
    max-width: 100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-items: center;
    align-items: center;
}
.template-0 .categories-horizontal .category-container {
    background-color: transparent;
}
.template-1 .categories-horizontal .category-container {
    background-color: rgba(255, 255, 255, 0.62);
}
.categories-horizontal .category-container > img {
    object-fit: contain;
    height: 34px;
    width: 100%;
}
.categories-horizontal > *:not(:last-child) {
    padding-right: 12px
}
.categories-horizontal::-webkit-scrollbar {
    display: none;
}
/*
.page.digital-menu .body {
    padding: 27px;
}
 */
.category-item {
    height: 100%;
}
.footer-digital-menu {
    background-size: contain;
    background-repeat: repeat-x;
    background-position-y: top;
    background-position-x: center;
    width: 100vw;
    margin-left: -27px;
    margin-top: 27px;
}
.footer-digital-menu > div {
    width: 100%;
    margin-top: 27px;
    padding: 27px;
    padding-top: 0;
    background-color: transparent;
}
.footer-digital-menu > div:last-child > p:last-child {
    margin-bottom: 0;
}
.footer-digital-menu > .logo-row img {
    max-width: 30%;
    min-height: 0;
    margin: auto;
    object-fit: contain;
}
.footer-digital-menu > .logo-row {
    padding: 0 27px;
}
.online-menu-category-line {
    width: 100%;
    border-color: #fff;
    margin-right: 27px;
}

/* VOTES */

.online-menu-score {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 0px;
    justify-content: space-between;
    padding-bottom: 190px;

}
.online-menu-item-score {
    background: rgba(255, 255, 255, 0.2);
    min-width: 60px;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.online-menu-item-score p {
    color: #fff;
}
.online-menu-item-product-container.questionnaire {
    background-color: rgba(255, 255, 255, 0.2);
}
.online-menu-item-score.selected, .online-menu-item-product-container.questionnaire.selected {
    background-color: #fff;
    color: #fff;
}
.footer-digital-menu.questionnaire {
    position: fixed;
    bottom: 0;
}
.questionnaire.main-title, .questionnaire.title {
    font-size: 13pt;
    padding: 20px 0;
    font-family: "Poppins SemiBold", Poppins, sans-serif;
    font-weight: 500;
    line-height: 1.4;
}
.questionnaire.title {
    color: #fff;
    display: inline-grid;
}
.questionnaire.main-title {
    font-size: 13pt;
    color: #fff;
    font-weight: 600;
}
.questionnaire.divider {
    width: 100%;
    border: 1px solid #fff;
    border-top: 0;
}
.questionnaire.background.fixed::before {
    background: linear-gradient(180deg, rgb(23, 24, 26) 0%, rgba(255,255,255,0) 40%);;
    backdrop-filter: none;
}
.questionnaire .footer-digital-menu > div {
    padding-bottom: 15px;
}
.questionnaire.footer-digital-menu .logo-row {
    margin-bottom: -20px;
}
.questionnaire.footer-digital-menu .online-menu-text-footer.bar-info {
    margin: 0;
}
.questionnaire .form-container form input  { /* Edge */
    color: white;
    font-size: 15pt;
}
.questionnaire .form-container   { /* Edge */
    padding-top: 60px;
}
.questionnaire .placeholder {
    position: relative;
    margin-bottom: -15px;
    height: 0;
    font-size: 15pt;
    color: white;
    font-family: Poppins, sans-serif;
}
.questionnaire .input-loadUserActivation {
    margin-bottom: 36px;
    background: transparent;
    border-bottom: 1px solid rgb(224, 0, 118);
    padding-left: 0px;
    border-radius: 0;
}
.questionnaire .checkbox-container {
    color: #fff;
    font-size: 9pt;
}

.questionnaire input[type='checkbox'] {
    margin: 0 14px;
    height: 30px;
    width: 35px;
    outline: none;
    appearance: none;
    border: 1px solid white;
    border-radius: 5px;
    align-self: self-start;
}
.questionnaire .checkbox-container span {
    font-family: "Poppins-Light", "Poppins";
    font-weight: 100;
    font-size: 8pt;
}
