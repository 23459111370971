.page.edit-menu {
    height: 100% !important;
}
.page.edit-menu .body {
    padding-right: 50px !important;
}
.masonry-edit-menu {
    margin-top: 25px;
    padding-bottom: 220px;
}
.menu-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: fit-content;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}
.menu-category-header {
    background: #fff;
    border-bottom: 1px solid #707070;
    height: 45px;
    padding-left: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
}
.menu-category-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.menu-category-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 35px;
}
.menu-category-product:not(:last-child) {
    border-bottom: 0.25pt solid #c3c3c3;
}



.menu-category-product-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.menu-category-product-left-desc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.menu-category-product-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.p-title-category {
    padding-top: 20px;
}
.p-title-product {
    margin-top: 10px;
}
.p-desc-product {
    margin-right: 4px;
}
