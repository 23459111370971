p.icons-category {
    padding: 0 12px;
}
.category-icons-container {
    display: flex;
    width: 100%;
    height: fit-content;
}
.category-icons-container img {
    max-height: 40px;
    object-fit: contain;
}
.category-icons {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}
.icon-category-container {
    align-self: center;
    height: 60px;
    width: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    margin: 8px 4px;
    cursor: pointer;
}
.icon-category-container.selected {
    border: 2px solid #fff;
}